import { NetworkParams } from '../models/network-params.model';

export class NetworkParamsHandler {
  constructor(private networkParams: NetworkParams[]) {}

  isEmpty(): boolean {
    return this.networkParams.length === 0;
  }

  getAllNetworkName(): string[] {
    return this.networkParams.map((networkParams: NetworkParams) => networkParams.network);
  }

  getNetworkFromName(network: string): NetworkParams {
    return this.networkParams.find((networkParams: NetworkParams) => networkParams.network === network);
  }

  isNetworkExists(network: string) {
    return this.networkParams.some((networkParams: NetworkParams) => networkParams.network === network);
  }
}
