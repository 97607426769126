import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { NetworkParams } from '../models/network-params.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkParamsApiService {
  constructor(private readonly apiService: ApiService) {}

  getAvailableNetworks(): Observable<NetworkParams[]> {
    return this.apiService.get('network-params/available-network');
  }
}
