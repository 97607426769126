import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { NetworkParams } from '../models/network-params.model';
import { NetworkParamsApiService } from './network-params-api.service';
import { takeUntil } from 'rxjs/operators';
import { NetworkParamsHandler } from '../utils/network-params-handler';

@Injectable({
  providedIn: 'root',
})
export class NetworkParamsStoreService {
  private networksSource = new BehaviorSubject<NetworkParamsHandler>(new NetworkParamsHandler([]));
  networks$ = this.networksSource.asObservable();
  private selectedNetworkSource = new BehaviorSubject<string>(null);
  selectedNetwork$ = this.selectedNetworkSource.asObservable();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private networkParamsApiService: NetworkParamsApiService) {}

  setAvailableNetworks() {
    this.networkParamsApiService
      .getAvailableNetworks()
      .pipe(takeUntil(this.destroy$))
      .subscribe((networks: NetworkParams[]) => {
        this.networksSource.next(new NetworkParamsHandler(networks));
      });
  }

  changeSelectedNetwork(network: string) {
    this.selectedNetworkSource.next(network);
  }
}
